
import Banner from "@/components/Banner.vue";
import Giftcard from "@/components/Giftcard.vue";
import LandingPageNews from "@/components/LandingPageNews.vue";
import { TheSalonSection } from "@fixit/fixit-salons-info";
import { TheInstagramFeed } from "@fixit/instagram-images";
import FIcon from "@fixit/icons/src/components/FIcon.vue";
import CmsHomepageModuleEnum from "@/static/enums/modules/cmsHomepageModuleEnum";
import { mapWaitingActions } from "vue-wait";
import { User } from "@fixit/fixit-no-header/src/models";
import RecEngineModule from "../components/RecEngineModule.vue";
import { ItemType, Location } from "@fixit/v-recommendation-box/src/enums";
export default {
  head() {
    return {
      title: `${this.$store.getters["cmsConfigHomepage/getChainName"]}`,
      titleTemplate: `%s |  ${this.$store.getters["cmsConfigHomepage/getHomepageSiteUrl"]}`,
      meta: [
        {
          hid: "og:titlw",
          name: "og:titlw",
          content: `${this.$store.getters["cmsConfigHomepage/getChainName"]} |  ${this.$store.getters["cmsConfigHomepage/getChainName"]}`,
        },
        {
          hid: "og:description",
          name: "og:description",
          content:
            this.$store.getters["cmsConfigHomepage/getCmsConfigHomepage"]
              ?.homepageDescription,
        },
        {
          hid: "og:url",
          name: "og:url",
          content:
            this.$store.getters["cmsConfigHomepage/getCmsConfigHomepage"]
              ?.siteUrl,
        },
        {
          hid: "og:image",
          name: "og:image",
          content: this.$store.getters["cmsConfigHomepage/getLogo"],
        },
      ],
    };
  },
  components: {
    Banner,
    Giftcard,
    LandingPageNews,
    TheSalonSection,
    TheInstagramFeed,
    FIcon,
    RecEngineModule,
  },
  layout: "HomepageLayout",
  name: "Home",
  data: () => ({
    isLandingPage: true,
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
    itemsFetched: false,
    isMounted: false,
    itemType: ItemType,
    location: Location,
  }),
  computed: {
    homepageModules() {
      var modules = this.$store.getters["cmsConfigHomepage/getModules"];
      let showPrices = modules.find(
        (m) => m.moduleId == CmsHomepageModuleEnum.PRICES
      )?.visible;
      let prices = true;
      if (showPrices != undefined && showPrices != null) prices = showPrices;
      return {
        treatments: modules.find(
          (m) => m.moduleId == CmsHomepageModuleEnum.HOMEPAGETREATMENTS
        )?.visible,
        giftcards:
          this.$store.getters["cmsConfigHomepage/getGiftcardActiveStatus"],
        news:
          modules.find(
            (m) => m.moduleId == CmsHomepageModuleEnum.HOMEPAGEARTICLES
          )?.visible && this.$store.getters["cmsConfigHomepage/newsVisible"],
        departments: modules.find(
          (m) => m.moduleId == CmsHomepageModuleEnum.CHAINDEPARTMENTS
        )?.visible,
        instagram: modules.find(
          (m) => m.moduleId == CmsHomepageModuleEnum.IMAGESINSTAGRAM
        )?.visible,
        products:
          modules.find(
            (m) => m.moduleId == CmsHomepageModuleEnum.SUPPLIERBRANDS
          )?.visible &&
          this.$store.getters["cmsConfigHomepage/getWebshopActiveStatus"],
        prices: prices,
      };
    },
    salons() {
      return this.$store.getters["salon/getSalons"];
    },
    selectedSalon() {
      return this.$store.getters["cart/getSelectedSalon"];
    },
    siteIds() {
      return this.$store.getters["salon/getSiteIds"];
    },
    news() {
      let news = this.$store.getters["news/getNews"];
      return news ? news : [];
    },
    instagramImages() {
      return this.$store.getters["instagramImages/getInstagramImages"];
    },
    chainId() {
      return this.$store.getters["cmsConfigHomepage/getChainId"];
    },
    isDraft() {
      return this.$store.getters["cmsConfigHomepage/isDraftMode"];
    },
    user() {
      return new User(this.$store.getters["app/get"]("user"));
    },
    cartTreatmentIds() {
      return this.$store.getters["cart/getCartTreatments"].map(
        (x) => x.urlSafe
      );
    },
    productIds() {
      return this.$store.getters["cart/getCartProducts"]
        .map((x) => x.base64Id)
        .concat(
          this.$store.getters["cart/getCartBundles"].map((x) => x.base64Id)
        );
    },
    isSingleSite() {
      return this.$store.getters['salon/getIsSingleSite']
    },
  },
  async mounted() {
    let cart = this.$store.getters['cart/getCart']
    if (!(cart && cart.siteId) && !this.isSingleSite) {
      this.$store.commit("cart/setSelectedSalon", null)
    }
    this.isMounted = true;
    if (this.homepageModules.instagram) {
      await this.$store.dispatch("instagramImages/fetchLastestInstagramImages");
    }
  },
  async created() {
    if (!this.news.length) {
      this.fetchNewsBySiteIds();
    }
  },
  methods: {
    ...mapWaitingActions("news", {
      fetchNewsBySiteIds: "loading initailize homepage fetchNewsBySiteIds",
    }),
  },
};
