

export default {
  props: { isLandingPage: Boolean, news: { type: Array, default: null } },

  data: function () {
      return {
          perPage: this.isLandingPage ? 2 : 6,
          page: 1,
      };
  },

  computed: {
    pages() {
      return this.news
        ? Math.ceil(this.news.length / 6)
        : 1;
    },
    slicedNews() {
      const start = this.page * this.perPage - this.perPage, 
      end = start + this.perPage;
      return this.news.slice(start, end);
    }
  },

  methods: {
    viewState(index) {
      if (this.isLandingPage && index < 2) {
        return true;
      } else if (!this.isLandingPage) {
        return true;
      }
      return false;
    },
    nextPage(page) {
      this.pageNumber = page;
    },

    // Disables the "Load more news" button if the slicedNews array is as long as the entire news array.
    endOfArticles(){
      if(this.slicedNews.length === this.news.length) {
        return false
      }
      return true
    },

    getImage(item) {
      if (item == null || item.imgUrl == null || item.imgUrl === undefined) {
        return null
      }
      if (typeof String.prototype.replaceAll == "undefined") {
        String.prototype.replaceAll = function(match, replace) {
          return this.replace(new RegExp(match, 'g'), () => replace);
        }
      }
      return item.imgUrl.replaceAll('"', '')
    },

    formatBody(text) {
      if (typeof String.prototype.replaceAll == "undefined") {
        String.prototype.replaceAll = function(match, replace) {
          return this.replace(new RegExp(match, 'g'), () => replace);
        }
      }
      return text.replaceAll('\n', '</br>')
    },
  },
};
