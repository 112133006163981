
import ChooseSalonDialog from "@fixit/fixit-no-header/src/components/Common/CommonComponents/chooseSalonDialog.vue";
import { bannerList } from "@/static/banners";
export default {
  props: { isLandingPage: Boolean },
  components: { ChooseSalonDialog },
  data: () => ({
    continousCycle: true,
    isMounted: false,
  }),
  mounted() {
    this.isMounted = true;
    if (this.banners.length >= 2) {
      const nextButton = document.querySelector(".v-window__next button");
      const prevButton = document.querySelector(".v-window__prev button");
      const delimiters = document.querySelector(".v-carousel__controls");
      if (this.$vuetify.breakpoint.smAndDown) {
        nextButton.style.display = "none";
        prevButton.style.display = "none";
      }

      nextButton.addEventListener("click", () => {
        this.continousCycle = false;
      });
      prevButton.addEventListener("click", () => {
        this.continousCycle = false;
      });
      delimiters.addEventListener("click", () => {
        this.continousCycle = false;
      });
    }
  },
  computed: {
    banners() {
      if (this.isLandingPage) {
        // var activeBanners = bannerList()
        var activeBanners = this.$store.getters["cmsConfigHomepage/getBanners"];
        return this.sortBanners(activeBanners.filter((x) => x.active))
      }
      return this.bannerImg;
    },
    salons() {
      let selectedSalon = this.$store.getters['cart/getSelectedSalon']
      let bookingEnabledForSelectedSalon = this.$store.getters['salon/bookingEnabledForSite'](this.$store.getters['cart/getSelectedSalon'])
      if (selectedSalon && bookingEnabledForSelectedSalon) {
        return this.$store.getters["salon/getSalons"].filter(x => x.siteId === selectedSalon);
      } else {
        return this.$store.getters["salon/getSalons"].filter(x => x.enabled)
      }
    },
    isDraft() {
      return this.$store.getters["cmsConfigHomepage/isDraftMode"];
    },
  },
  methods: {
    giftcardRedirect() {
      this.$router.push('giftcard')
    },
    textPlacement(config) {
      if (config && config !== null && config !== undefined) {
        if (config.includes("right")) return "text-right";
        else if (config.includes("center")) return "text-center";
        else if (config.includes("top") || config.includes("bottom")) return "text-center";
        else return "";
      } else return 'text-center'
    },
    sortBanners(banners) {
      return banners.sort(function(a, b) {
        return a.sortNo - b.sortNo;
      });
    },
    opacityValue(config) {
      var parsed = JSON.parse(config)
      if (parsed && parsed.ImageOpacity && parsed.ImageOpacity != null && parsed.ImageOpacity != undefined) {
        return parsed.ImageOpacity
      } else {
        return 0
      }
    },
    subtitlePlacement(config) {
      if (config && config !== null && config !== undefined) {
        if (config.includes("right")) return "end";
        else if (config.includes("center")) return "center";
        else if (config.includes("top") || config.includes("bottom")) return "center";
        else return "start";
      } else return 'center'
    },
  },
};
