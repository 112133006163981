import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=1e3fa53d"
import script from "./Banner.vue?vue&type=script&lang=js"
export * from "./Banner.vue?vue&type=script&lang=js"
import style0 from "./Banner.vue?vue&type=style&index=0&id=1e3fa53d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChooseSalonDialog: require('/salonhomepage/client/components/chooseSalonDialog.vue').default})
